import Vue from 'vue'
import VueScroll from 'vuescroll'

Vue.use(VueScroll, {
  ops: {
    vuescroll: {
      mode: 'slide',
      sizeStrategy: 'number',
      pullRefresh: {
        enable: true,
        tips: {
          deactive: '下拉刷新',
          active: '请松开',
          start: '正在刷新中',
          beforeDeactive: '刷新成功'
        }
      },
      pushLoad: {
        enable: true,
        tips: {
          deactive: '上拉加载',
          active: '请松开',
          start: '正在加载中',
          beforeDeactive: '加载成功'
        },
        auto: true,
        autoLoadDistance: 20
      },
      scroller: {
        bouncing: {
          top: 100,
          bottom: 0,
          left: 0,
          right: 0
        }
      }
    },
    scrollPanel: {
      scrollingX: false,
      scrollingY: true
    },
    rail: {},
    bar: {
      background: '#4941A5',
      disable: true
    }
  }
})
