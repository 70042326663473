<template>
  <div class="flex-column">
    <div class="top flex flex-main-between flex-cross-center">
      <div
        class="flex flex-cross-center"
        @click.stop="showSelect=!showSelect"
      >
        <div>{{tenantName}}</div>
        <!-- <img v-if="tenants && tenants.length>1 && showSelect" src="@/assets/arrow_down.svg" /> -->
        <img
          v-if="tenants && tenants.length>1 "
          src="@/assets/arrow_right.svg"
        />
      </div>
      <div>{{userInfo.name}}</div>
    </div>
    <slot name="main"></slot>
    <div>
      <transition name="slide-up">
        <div
          v-show="showSelect"
          class="select-bar"
          @click.stop.prevent
          @dblclick.stop.prevent
        >
          <div class="select-header flex flex-between-center">
            <div
              class="btn"
              @click="cancelSelect"
            >取消</div>
            <div>选择租户</div>
            <div
              class="btn"
              @click="onConfirm"
            >确定</div>
          </div>
          <div class="select-content">
            <!-- <base-scroll method="get" :load-data-url="`/h5/user/getTenants`" mode="native"  @choose="onTenantTap">
                            <template v-slot="{rows}">
                                <div class="item" v-for="(row,index) in rows" :data-index="index" :key="row.id">
                                <span>{{row.name}}</span>
                                <img src="@/assets/yes.svg" class="icon" v-if="currentSelect&&currentSelect.id===row.id"/>
                                </div>
                            </template>
                        </base-scroll> -->
            <vue-scroll
              ref="vs"
              :ops="ops"
            >
              <div
                style="overflow: hidden"
                data-index="-1"
              >
                <div
                  class="item"
                  v-for="(row,index) in tenants"
                  :data-index="index"
                  :key="index"
                  @click="onTenantTap(row)"
                >
                  <span>{{row.name}}</span>
                  <img
                    src="@/assets/yes.svg"
                    class="icon"
                    v-if="currentSelect&&currentSelect.id===row.id"
                  />
                </div>
              </div>
            </vue-scroll>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseScroll from '@/components/base-scroll'
import VueScroll from 'vuescroll'
export default {
  components: { VueScroll },
  data () {
    return {
      showSelect: false,
      currentSelect: null,
      ops: {
        vuescroll: {
          mode: 'slide',
          sizeStrategy: 'percent',
          pullRefresh: {
            enable: false,
          },
          pushLoad: {
            enable: false,
          },
          scroller: {
            bouncing: {
              top: 50,
              bottom: 50,
              left: 0,
              right: 0,
            },
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'tenantName', 'tenants']),
  },
  methods: {
    onTenantTap (tenant) {
      console.log(tenant)
      this.currentSelect = tenant
    },
    onConfirm () {
      if (this.currentSelect) {
        const { id: tenantId, name: tenantName } = this.currentSelect
        this.$store.commit('SET_TENANT', { tenantId, tenantName })
        this.$store.dispatch('SetTenant', this.currentSelect).then(() => {
          this.$router.replace({ path: '/' })
        })
      }
      this.showSelect = false
      this.currentSelect = null
    },
    cancelSelect () {
      this.showSelect = false
      this.currentSelect = null
    },
  },
}
</script>
<style lang="stylus" scoped>
.top {
  height: 80rpx;
  padding: 10rpx 20rpx;
  background-color: #4941A5;
  color: #fff;
}

.select-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .select-header {
    font(32, 80, $text);
    background: #ebebf0;
    padding: 0 32rpx;

    .btn {
      color: #1979ff;
    }
  }

  .select-content {
    height: 600rpx;
    background: #fff;

    .item {
      height: 96rpx;
      position: relative;
      text-align: center;
      border-bottom: 1rpx solid rgba(31, 31, 52, 0.3);
      font(28, 96, $text);

      .icon {
        position: absolute;
        top: 24rpx;
        right: 32rpx;
      }
    }
  }
}
</style>
