<template>
  <div class="container flex-column">
    <div class="header"><slot name="header"></slot></div>
    <div class="flex-auto relative main">
      <div class="absolute">
        <slot></slot>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
  export default {
    name: 'list-template'
  }
</script>

<style scoped lang="stylus">
.header
  height:160rpx;
  box-sizing border-box
  background:rgba(12,12,58,0.45);
</style>
