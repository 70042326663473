<template>
  <div id="app2">
<!--    <keep-alive>-->
      <router-view class="container"></router-view>
<!--    </keep-alive>-->
    <Loading/>
  </div>
</template>
<script>
  import Loading from '@/components/loading'
  export default {
    name: 'App',
    components: {
      Loading
    },
    mounted () {
      setTimeout(() => {
        document.getElementById('app2').style.height = window.innerHeight + 'px'
      }, 100)
    }
  }
</script>
<style lang="stylus">
@import '~@/styles/common.styl';
#app2
  font-family: 'Microsoft YaHei', Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  transition: all linear 0.5s
  overflow auto
</style>
