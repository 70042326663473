import axios from 'axios'
import store from '../store'
import router from '../router'
import qs from 'qs'
import Toast from '@/components/toast.js'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 300000, // 请求超时时间
  showError: true, // 是否显示错误
  showLoading: true, // 是否显示进度条
})

var CancelToken = axios.CancelToken
var source = CancelToken.source()

// console.log(process.env.VUE_APP_BASE_API)

// request拦截器
service.interceptors.request.use(config => {
  // console.log('config', config)
  config.showError = config.showError === undefined ? true : config.showError
  config.showLoading = config.showLoading === undefined ? true : config.showLoading
  config.showLoading && store.commit('SHOW_LOADING')
  config.cancelToken = source.token

  var token = store.getters.token
  // console.log('tk:', token)
  if (token) {
    config.headers.Authorization = `${token}` // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  var tid = store.getters.tid
  // const tid = '8a80cb81677d501901677d50f1720000'
  if (config.needTenant && tid) {
    config.headers['X-Tenant-Id'] = tid
  } else if (config.needTenant) {
    source.cancel('服务信息获取失败，请关闭后重新进入')
  }
  config.headers['X-Source-Id'] = '458D2213-65E0-4313-A567-25A953412A24'
  //  平台账号模拟租户ID
  if (store.getters.accountType === 'PLATFORM') {
    config.headers['X-Platform-Tenant-Id'] = store.getters.tenantId
  }

  /**
   * 组装分页信息
   */
  if (config.pageInfo) {
    config.headers['X-Page-Index'] = (config.pageInfo.pageIndex ? config.pageInfo.pageIndex : 1) - 1
    config.headers['X-Page-Size'] = config.pageInfo.pageSize ? config.pageInfo.pageSize : 20
    if (config.pageInfo.orders) {
      config.headers['X-Page-Sort'] = config.pageInfo.orders
    }
  }
  if (!config.dataType) {
    config.dataType = 'json'
  }
  if ((config.method === 'post' || config.method === 'put') && config.dataType !== 'json' && config.dataType !== 'raw') {
    if (!config.data) {
      config.data = []
    }
    config.data = qs.stringify(config.data)
  }
  return config
}, error => {
  store.commit('HIDE_LOADING')
  // Do something with request error
  console.error(error) // for debug
  let msg = '访问失败啦，网络可能不稳定，请稍后重试'
  if (error.code === 'ECONNABORTED') {
    msg = '访问超时啦，请检查您的网络是否正常'
  }
  Toast(msg)
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({
    msg
  })
})

// respone拦截器
service.interceptors.response.use(
  response => {
    // console.debug('reponse', response)
    store.commit('HIDE_LOADING')
    const res = response.data
    if (res.status) {
      response.config.showError && res.status && Toast.show(res.msg || '短路啦，请稍后重试')
      return Promise.reject(res)
    }
    // console.log(res.data)
    return Promise.resolve(res.data)
  },
  error => {
    store.commit('HIDE_LOADING')
    // 400 bad request 401 auth fail 403 forbid 404 not found 500 internal error
    console.error('error', error.response) // for debug
    var message = error.message
    let status = 520
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          message = '错误的请求'
          break
        case 401:
          store.dispatch('Logout')
          message = (error.response.data && error.response.data.msg) ? error.response.data.msg : '认证失败，请重新登录'
            router.push({
              name: 'login',
              query: {
                lastPath: router.currentRoute.path
              }
            })
            break
        case 403:
          message = '未授权请求'
          break
        case 404:
          message = '请求的资源不存在'
          break
        case 500:
          message = '服务器内部错误'
          if (error.response.data && error.response.data.status) {
            message = error.response.data.msg ? error.response.data.msg : message
          }
          break
      }
      status = error.response.data.status
    } else {
      message = '网络请求错误，请检查网络状况稍后重试'
    }

    error.config && error.config.showError && Toast.show(message)
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status,
      msg: message
    })
  }
)

export default service
