import Vue from 'vue'
import toast from './toast.vue'
const ToastConstructor = Vue.extend(toast)



class Toast {
  constructor () {
    this.instance = new ToastConstructor().$mount()
    document.body.appendChild(this.instance.$el)
  }

  show (options) {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    this.timer && clearTimeout(this.timer)
    this.instance.visible = false
    this.instance.message = options.message
    this.instance.visible = true
    this.timer = setTimeout(() => (this.instance.visible = false), options.duration || 2000)
  }

  destroy () {
    document.body.removeChild(this.instance)
    this.instance = null
  }
}

export default new Toast()
