const loading = {
  state: {
    building: {},
    block: {},
    floor: {},
    room: {},
    node: {}
  },
  mutations: {
    SET_BUILDING: (state, building) => {
      state.building = building
    },
    SET_BLOCK: (state, block) => {
      state.block = block
    },
    SET_FLOOR: (state, floor) => {
      state.floor = floor
    },
    SET_ROOM: (state, room) => {
      state.room = room
    }
  },
  actions: {
    SetBuilding ({ commit }, building) {
      commit('SET_BUILDING', building)
      commit('SET_BLOCK', {})
      commit('SET_FLOOR', {})
      commit('SET_ROOM', {})
    },
    SetBlock ({ commit }, block) {
      commit('SET_BLOCK', block)
      commit('SET_FLOOR', {})
      commit('SET_ROOM', {})
    },
    SetFloor ({ commit }, floor) {
      commit('SET_FLOOR', floor)
      commit('SET_ROOM', {})
    }
  },
  getters: {
    building (state) {
      return state.building
    },
    block (state) {
      return state.block
    },
    floor (state) {
      return state.floor
    },
    room (state) {
      return state.room
    }
  }
}

export default loading
