<template>
  <transition name="fade">
    <div v-if="visible" class="toast">
      <div class="content">{{message}}</div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      message: ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.toast
  position: fixed
  left: 50%
  top 50%
  transform: translate(-50%,-50%)
  text-align: center
  word-wrap: break-word
  padding: 26rpx 40rpx
  z-index: 9000
  box-sizing: border-box
  width: 440rpx
  color: #fff
  border-radius: 20rpx
  background-color: rgba(0,0,0,0.8)
  .content
    font-size: 28rpx
    line-height: 40rpx
.fade-enter-active,.fade-leave-active
  transition: all .4s
.fade-enter,.fade-leave-to
  opacity: 0
</style>
