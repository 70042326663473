<template>
  <transition name="fade">
    <template>
      <div class="loading" v-show="LOADING">
        <div class="box">
          <img src="../assets/snow.svg" class="loading-icon">
        </div>
      </div>
    </template>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'loading',
    computed: {
      ...mapGetters(['LOADING'])
    }
  }
</script>

<style scoped lang="stylus">
  @keyframes jump {
    0% {
      transform: translateY(-180px) scaleY(1);
      animation-timing-function: cubic-bezier(.895, .03, .685, .22);
    }
    80% {
      transform: scaleY(2) scaleX(.6);
    }
    90% {
      transform: scaleY(.4) scaleX(1);
    }
    100% {
      transform: scaleY(.25) scaleX(2);
    }
  }
  @keyframes dance {
    0% {
      transform: rotate(0deg);
    }
    21% {
      transform: rotate(0deg);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }
    28% {
      transform: rotate(-1turn);
    }
    71% {
      transform: rotate(-1turn);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }
    78% {
      transform: rotate(1turn);
    }
    100% {
      transform: rotate(1turn);
    }
  }
  .loading {
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    .box {
      position: absolute;
      width: 150rpx;
      height: 150rpx;
      top: 50%;
      left: 300rpx;
      animation: jump .5s linear infinite alternate;
      .loading-icon {
        width: 100%;
        height: 100%;
        animation: dance 10s cubic-bezier(.645, .045, .355, 1) .5s infinite;
      }
    }
  }
</style>
