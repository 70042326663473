<template>
  <layout-template>
    <template slot="main">
      <list-template>
        <template v-slot:header>
          <div class="flex flex-between-center container header">
            <div>
              <div class="nickname">{{userInfo.name || ''}}</div>
              <div class="department">{{tenantName || ''}}</div>
            </div>
            <div class="logout flex flex-center" style="color: #f0f9eb" @click="onLogout">注销登录</div>
          </div>
        </template>
        <template>
          <div class="menu-container flex-column">
            <div class="menu-body flex flex-center" v-if="deviceFlag" @click="onDeviceManagementButtonClick">
              <img class="portrait " src="@/assets/device_management.svg"/>
              <div class="title flex-column">设备管理</div>
            </div>
            <div class="menu-body flex flex-center" v-if="deviceBindFlag"  @click="onDeviceSetupButtonClick" style="margin-top: 25px;">
              <img class="portrait " src="@/assets/device_setup.svg"/>
              <div class="title flex-column">设备绑定</div>
            </div>
            <div v-if="$store.getters.accountType!='PLATFORM'  && refundFlag" class="menu-body flex flex-center" @click="onRefundManagementButtonClick">
              <img class="portrait " src="@/assets/refund.png"/>
              <div class="title flex-column">退款管理</div>
            </div>
          </div>
        </template>
      </list-template>
    </template>
  </layout-template>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      deviceBindFlag: false,
      deviceFlag: false,
      refundFlag: false
    }
  },
  computed: {
      ...mapGetters(['userInfo', 'tenantName', 'tenantId'])
  },
  created () {
      console.log('this.accountType:', this.$store.getters.accountType)
      console.log('this.menus:', this.$store.getters.userInfo.menus)
      console.log('this.deviceBind:', this.$store.getters.userInfo.menus.filter(m => m.code === 'deviceBind'))
      var deviceBindMenu = this.$store.getters.userInfo.menus.filter(m => m.code === 'deviceBind')
      if (deviceBindMenu && deviceBindMenu.length > 0) {
        this.deviceBindFlag = true
      }
      var deviceMenu = this.$store.getters.userInfo.menus.filter(m => m.code === 'device')
      if (deviceMenu && deviceMenu.length > 0) {
        this.deviceFlag = true
      }
      var refundMenu = this.$store.getters.userInfo.menus.filter(m => m.code === 'refund')
      if (refundMenu && refundMenu.length > 0) {
        this.refundFlag = true
      }
  },
  methods: {
    onLogout () {
      this.$store.dispatch('Logout').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },
    onDeviceManagementButtonClick () {
        this.$router.push({ path: '/control' }).catch(err => {
          console.log(err)
        })
      },
    onDeviceSetupButtonClick () {
        this.$router.push({ path: '/setup' })
      },
    onRefundManagementButtonClick () {
        this.$router.push({ path: '/refund' })
      }
    }
  }
</script>
<style lang="stylus" scoped>
  .header
    padding 0 32rpx
  .nickname
    font(32,48,#fff)
    margin-bottom 8rpx
  .department
    font(24,40,rgba(255,255,255,.65))
    ellipse(500)
  .logout
    width:128rpx;
    height:48rpx;
    background:rgba(255,255,255,0.35);
    border-radius:24rpx;
    font(24,40,#fff)
  .block
    background #fff
    border-radius 20rpx
    margin 40rpx 32rpx
    height 112rpx
    min-height 20px
    padding 0 32rpx
    font(28,44,$text)
.menu-container {
    // width:200rpx;
    margin: 0 auto;
    text-align: center;

    .menu-body {
      display: flex;
      height: 100px;
      margin-right: 65px;
      margin-left: 55px;
      border-radius: 5px;
      background-color: #fff;

      .portrait {
        margin-left: -80rpx;
      }

      .title {
        margin-left: 80 rpx;
        font-size: 32 rpx;
        font-weight: 600;
        color: #3E4653;
      }

    }

    .menu-body:first-child {
      margin-top: 50px;
    }

    .menu-body:last-child {
      margin-top: 25px;
    }
  }
</style>
