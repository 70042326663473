const loading = {
  state: {
    loading: false
  },
  mutations: {
    SHOW_LOADING: state => {
      state.loading = true
    },
    HIDE_LOADING: state => {
      state.loading = false
    }
  },
  getters: {
    LOADING (state) {
      return state.loading
    }
  }
}

export default loading
