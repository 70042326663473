import Vue from 'vue'
import Vuex from 'vuex'
import loading from './modules/loading'
import user from './modules/user'
import building from './modules/building'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
    initialiseStore (state) {
      this.replaceState(
        Object.assign(
          state,
          JSON.parse(localStorage.getItem('store'))
        )
      )
    }
  },
  actions: {
  },
  modules: {
    loading,
    user,
    building
  }
})

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state))
})

export default store
