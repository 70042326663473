import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './scroll.config'
import toast from '@/components/toast.js'
import fetch from './utils/fetch'
import ListTemplate from '@/components/template/list-template'
import LayoutTemplate from '@/views/layout/layout'
// import MuseUI from 'muse-ui'
// import 'muse-ui/dist/muse-ui.css'
// Vue.use(MuseUI)
// import 'muse-ui/lib/styles/base.less'
// import { Button, Radio, Grid } from 'muse-ui'
// import 'muse-ui/lib/styles/theme.less'
// Vue.use(Button)
// Vue.use(Radio)
// Vue.use(Grid)

const isIOS = (/iphone|ipod|ipad/i.test(navigator.appVersion))

if (!isIOS) {
  import('./tap.config')
}
// import './mock'

Vue.prototype.$DEBUG = process.env.NODE_ENV === 'development'
Vue.prototype.$isWx = isWexin()
Vue.config.productionTip = !Vue.prototype.$DEBUG
Vue.config.devtools = Vue.prototype.$DEBUG
Vue.config.performance = Vue.prototype.$DEBUG
Vue.config.silent = !Vue.prototype.$DEBUG
Vue.prototype.$toast = toast
Vue.prototype.$axios = fetch
Vue.component('list-template', ListTemplate)
Vue.component('layout-template', LayoutTemplate)


function isWexin () {
  if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
    return true
  }
  return false
}

/**
 * 处理iOS 微信客户端6.7.4 键盘收起页面未下移bug
 */
isIOS && document.addEventListener('blur', (e) => {
  // 这里加了个类型判断，因为a等元素也会触发blur事件
  ['input', 'textarea'].includes(e.target.localName) && document.body.scrollIntoView(true)
}, true)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
