<template>
  <vue-scroll :ops="options"
    @refresh-start="handleRS"
    @load-start="handleLS"
    ref="vs"
  >
    <div @click="onTap" style="overflow: hidden" data-index="-1">
      <slot :rows="rows" :totalNum="totalNum"></slot>
    </div>
  </vue-scroll>
</template>

<script>
export default {
  data () {
    return {
      rows: [],
      totalNum: 0,
      isRefresh: false,
      pageInfo: {
        pageIndex: 1,
        pageSize: 10
      }
    }
  },
  props: {
    loadDataUrl: String,
    type: {
      type: String,
      default: ''
    },
    query: Object,
    method: {
      type: String,
      default: 'post'
    },
    mode: {
      type: String,
      default: 'slide'
    },
    filter: Function
  },
  computed: {
    options () {
      if (this.mode === 'slide') {
        return {}
      } else {
        return {
          vuescroll: {
            mode: 'native',
            sizeStrategy: 'percent',
            pullRefresh: {
              enable: false
            },
            pushLoad: {
              enable: false
            },
          },
          bar: {
            background: '#fff',
            disable: true
          }
        }
      }
    }
  },
  watch: {
    rows (nv) {
      nv.length && this.$refs.vs && this.$refs.vs.refresh()
    },
    query: {
      deep: true,
      immediate: true,
      handler (nv, ov) {
        // console.log(nv, ov)
        if (nv !== ov) {
          this.refresh()
        } else if (nv && ov) {
          // console.log('flag')

          let flag = true
          for (const key of Object.keys(nv)) {
            flag = nv[key] === ov[key]
          }
          for (const key of Object.keys(ov)) {
            flag = ov[key] === nv[key]
          }

          if (!flag) {
            this.refresh()
          }
        } else {
          this.refresh()
        }
      }
    },
    loadDataUrl (nv) {
      this.refresh()
    }
  },
  methods: {
    async handleLoadData (done) {
      if (!this.isRefresh && !this.noData) {
        this.isRefresh = true
        this.noData = false
        try {
          const data = await this.$axios[this.method](this.loadDataUrl, this.query, {
            showError: false,
            showLoading: false,
            pageInfo: this.pageInfo
          })
          if (this.mode === 'slide') {
            this.totalNum = data.totalElements || 0
            this.noData = data.last
            if (data && data.numberOfElements > 0) {
              this.rows.push(...data.content)
            } else {
              this.pageInfo.pageIndex > 1 && this.pageInfo.pageIndex--
            }
          } else {
            // this.totalNum = (data.children && data.children.length) || 0
            // if (this.filter && this.totalNum > 0) {
            //   this.rows = data.children.filter(this.filter)
            // } else {
            //   this.rows = data.children
            // }
            this.totalNum = data && data.length
            if (this.filter && this.totalNum > 0) {
              this.rows = data.filter(this.filter)
            } else {
              this.rows = data
            }
          }

          if (this.type === 'room') {
            this.rows.unshift({ name: '本层全部设备', id: 'room' })
          }
          this.$emit('total', this.totalNum)
        } catch (error) {
          this.$toast.show(error.msg)
        } finally {
          this.isRefresh = false
          done && done()
        }
      }
    },
    refresh (done) {
      this.rows = []
      this.pageInfo.pageIndex = 1
      this.noData = false
      this.handleLoadData(done)
    },
    handleRS (vsInstance, refreshDom, done) {
      this.refresh(done)
    },
    handleLS (vm, dom, done) {
      if (this.noData) {
        this.$toast.show('没有更多数据了')
        this.$nextTick(() => done())
      } else {
        this.pageInfo.pageIndex++
        this.handleLoadData(done)
      }
    },
    onTap (e) {
      // console.log(e)
      const index = this.findIndex(e.target)
      if (index > -1) {
        const data = this.rows[index]
        this.$emit('choose', data, e.target)
      }
    },
    findIndex (el) {
      while (el) {
        const index = el.getAttribute('data-index')
        if (index) {
          return +index
        } else {
          el = el.parentNode || el.parentElement
        }
      }
    }
  }
}
</script>
