const user = {
  state: {
    accountId: null,
    accountType: null,
    token: null,
    tenantId: null,
    tenantName: null,
    userInfo: {},
    tenants: []
  },

  mutations: {
    SET_ACCOUNT: (state, { accountId, accountType, token }) => {
      state.accountId = accountId || null
      state.accountType = accountType || null
      state.token = token || null
    },
    // SET_TOKEN: (state, token) => {
    //   state.token = token || null
    // },
    // SET_TENANT_NAME: (state, tenantName) => {
    //   state.tenantName = tenantName || null
    // },
    // SET_TENANT_ID: (state, tenantId) => {
    //   state.tenantId = tenantId || null
    // },
    SET_TENANT: (state, { tenantId, tenantName }) => {
      state.tenantId = tenantId || null
      state.tenantName = tenantName || null
    },
    SET_INFO: (state, user) => {
      state.userInfo = user
    },
    SET_TENANTS: (state, tenants) => {
      state.tenants = tenants
    }
  },
  getters: {
    accountType (state) {
      return state.accountType && state.accountType.name
    },
    token (state) {
      return state.token
    },
    tenantName (state) {
      return state.tenantName
    },
    tenantId (state) {
      return state.tenantId
    },
    userInfo (state) {
      return state.userInfo
    },
    tenants (state) {
      return state.tenants
    }
  },
  actions: {
    // 登录
    Login ({ commit }, account) {
      return new Promise((resolve, reject) => {
        // console.log('l:', accountId, accountType, token, tenantName, tenantId)
        commit('SET_ACCOUNT', account)
        // commit('SET_TOKEN', token)
        // commit('SET_TENANT_NAME', tenantName)
        // commit('SET_TENANT_ID', tenantId)
        if (account.accountType && account.accountType.name === 'TENANT') {
          commit('SET_TENANT', account)
        }
        if (account.accountType && account.accountType.name === 'PLATFORM') {
          commit('SET_TENANTS', account.tenants)
          if (account.tenants && account.tenants.length > 0) {
            const { id: tenantId, name: tenantName } = account.tenants[0]
            commit('SET_TENANT', { tenantId, tenantName })
          }
        }
        resolve()
      })
    },
    // 获取用户信息
    SetInfo ({ commit, state }, userInfo) {
      return new Promise((resolve, reject) => {
        commit('SET_INFO', userInfo)
        resolve()
      })
    },
    SetTenant ({ commit, state }, tenant) {
      return new Promise((resolve, reject) => {
        const { id: tenantId, name: tenantName } = tenant
        commit('SET_TENANT', { tenantId, tenantName })
        resolve()
      })
    },
    SetTenants ({ commit, state }, tenants) {
      return new Promise((resolve, reject) => {
        commit('SET_TENANTS', tenants)
        if (tenants && tenants.length > 0) {
          const { id: tenantId, name: tenantName } = tenants[0]
          commit('SET_TENANT', { tenantId, tenantName })
        }
        resolve()
      })
    },
    // 登出
    Logout ({ commit }) {
      return new Promise(resolve => {
        commit('SET_ACCOUNT', {})
        commit('SET_INFO', {})
        commit('SET_TENANTS', null)
        commit('SET_TENANT', {})
        resolve()
      })
    }
  }
}

export default user
