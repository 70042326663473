import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import store from '../store'
import fetch from '../utils/fetch'
import nprogress from 'nprogress'
import toast from '@/components/toast.js'
Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) {
//     return originalPush.call(this, location, onResolve, onReject)
//   }
//   return originalPush.call(this, location).catch(err => {
//     console.log(err)
//   })
// }

const load = componentName => () => import(`../views/${componentName}.vue`)

process.env.NODE_ENV !== 'development' && store.commit('initialiseStore')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: load('login')
  },
  {
    path: '/control',
    name: 'control',
    component: load('control/home')
  },
  {
    path: '/control/select/:id',
    name: 'process',
    props: true,
    component: load('control/process')
  },
  {
    path: '/control/room',
    name: 'room',
    component: load('control/room'),
    meta: {
      need: 'floor'
    }
  },
  {
    path: '/control/control/:id',
    name: 'deviceControl',
    props: true,
    component: load('control/deviceControl')
  },
  {
    path: '/control/group',
    name: 'deviceBath',
    component: load('control/deviceBath')
  },
  {
    path: '/control/device/:id',
    name: 'deviceDetail',
    props: true,
    component: load('control/deviceDetail')
  },
  {
    path: '/setup',
    name: 'setup',
    props: true,
    component: load('setup/form')
  },
  {
    path: '/setup/scan',
    name: 'scan',
    props: true,
    component: load('setup/scan')
  },
  {
    path: '/setup/control',
    name: 'debug',
    props: true,
    component: load('setup/control')
  },
  {
    path: '/refund',
    name: 'refund',
    props: true,
    component: load('refund/refund')
  },
  {
    path: '/refund/detail:id',
    name: 'refundDetail',
    props: true,
    component: load('refund/refundDetail')
  },
  {
    path: '/refund/back:id',
    name: 'refundBack',
    props: true,
    component: load('refund/refundBack')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.onError((error) => {
  console.log(error)
  toast.show(error)
})

router.beforeEach((to, from, next) => {
  // console.log('nav', to)
  // window.history.replaceState(null, null, window.location.href)
  nprogress.start()
  if (!store.getters.token || !(store.getters.userInfo && store.getters.userInfo.menus)) {
    // 未认证情况下，必须到登录界面  或者没有用户信息
    if (to.path !== '/login') {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    // 认证过，存在token
    console.log('to:', to, from, next)
    if (to.path === '/login') {
      // 不再进入登录界面
      next({ path: '/' })
    } else if (to.path === '/') {
      // 进入首页
      if (store.getters.userInfo.menus && store.getters.userInfo.menus.length > 0) {
        defaultRouter(to, next)
      } else {
        if (from.path === '/') {
          next(false)
        } else {
          next({ path: '/' })
        }
        throw new Error('账号未分权限')
      }
    } else if (to.path !== '/') {
      // 非首页
      if (to.matched.length === 0) {
        // 未匹配路由
        next({ path: '/' })
        throw new Error('没有路由权限')
      } else if (to.path.startsWith('/control') || to.path.startsWith('/setup') || to.path.startsWith('/refund')) {
          // 匹配到特定路由，判断账号拥有菜单与路由的匹配关系
            var existIndex = store.getters.userInfo.menus.findIndex(m => to.path.startsWith(m.url))
            if (existIndex < 0) {
              if (from.path === '/') {
                next(false)
              } else {
                next({ path: '/' })
              }
              throw new Error('账号未分权限')
            } else {
              // 默认路由
              defaultRouter(to, next)
            }
      } else {
        // 默认路由
        defaultRouter(to, next)
      }
    }
  }
})

function defaultRouter (to, next) {
  const needRoute = to.matched.find(record => !!record.meta.need)
  if (needRoute) {
    const need = needRoute.meta.need
    if (store.getters[need] && store.getters[need].id) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
}

router.afterEach((to, from) => {
  nprogress.done()
})
export default router
